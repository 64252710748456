import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { DepositType } from "../../Types/DepositeType";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Deposit = () => {
  const url = import.meta.env.VITE_API_URL;

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const status = searchParams.get("status");
  const reference = searchParams.get("refarence");
  const [deposits, setDeposits] = useState<DepositType[]>([]); // Specify type here
  const [depositLoading, setDepositLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [user, setUser] = useState<UserType | null>(null);

  // get user
  const accesstoken = getAccesstoken();
  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchDeposit = async () => {
      const getDeposit = await axios.get(
        `${url}/transaction/deposit/${user.id}`
      );

      setDeposits(getDeposit?.data?.deposits);
    };

    fetchDeposit();
  }, [user, url]);

  useEffect(() => {
    if (!status || !reference || !user) {
      return;
    }

    if (status === "success") {
      axios.put(`${url}/transaction/deposit/${user?.id}/${reference}`, {
        status: "success",
      });
    }
  }, [status, reference, user, url]);

  // const handleDeleteDeposit = async (deposit: DepositType) => {
  //   try {
  //     await axios.delete(`${url}/transaction/deposit/delete/${deposit?.id}`);
  //     setDeposits((prev) => prev.filter((d) => d?.id !== deposit?.id));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeposit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setDepositLoading(true);

      if (!user?.secret_key || !user?.payment_api_link) {
        toast.error("Please update your payment information first.");
        navigate("/keys");
      }

      if (!depositAmount) {
        return alert("Please enter a valid withdrawal amount.");
      }

      const response = await axios.post(`${url}/transaction/deposit`, {
        amount: Number(depositAmount),
        userId: user?.id,
        payment_api_link: user?.payment_api_link,
        secret_key: user?.secret_key,
      });

      if (response?.data) {
        toast.success("Deposit successful");
        setDepositAmount("");

        // console.log(response?.data);

        const paymentUrl = response?.data?.paymentResponse?.data?.payment_url;
        // console.log(paymentUrl);
        if (paymentUrl) {
          window.location.href = paymentUrl;
        }

        setDepositLoading(false);
      }

      // console.log(response?.data?.paymentResponse);
    } catch (error) {
      console.log(error);
      toast.error("Invalid withdrawal amount or insufficient balance.");
      setDepositLoading(false);
    }
  };

  return (
    <section className=" min-h-[85vh] custom_container py-10">
      <h1 className="text-3xl font-bold text-left mb-5">Deposits</h1>

      <div className="w-full lg:w-2/4 mt-10">
        <div className="flex flex-col gap-2">
          <form onSubmit={handleDeposit} className="flex flex-col gap-2">
            <input
              type="text"
              placeholder="Deposit Amount"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              className="py-2 px-3 border border-gray-700 rounded-md"
            />
            <button
              type="submit"
              className="px-3 py-2 bg-blue-500 border-none rounded-md text-white flex items-center justify-center"
              disabled={depositLoading}
            >
              {depositLoading ? <Loading /> : "Deposit"}
            </button>
          </form>
        </div>
      </div>

      <div className="mx-auto text-gray-100 mt-10">
        <div className="overflow-x-auto rounded-md">
          <table className="min-w-full text-xs">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col className="w-24" />
              {/* <col /> */}
            </colgroup>
            <thead className="bg-gray-600">
              <tr className="text-left text-lg">
                <th className="p-3">Transaction Id</th>
                <th className="p-3">Amount</th>
                <th className="p-3">Currency</th>
                <th className="p-3">Issued</th>
                <th className="p-3">Status</th>
                {/* <th className="p-3">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {deposits.map((deposit: DepositType) => (
                <tr
                  key={deposit?.id}
                  className="border-b border-opacity-20 border-gray-700 bg-gray-900 text-sm"
                >
                  <td className="p-3">
                    <p>{deposit?.reference}</p>
                  </td>
                  <td className="p-3">
                    <p>{deposit?.amount}</p>
                  </td>
                  <td className="p-3">
                    <p>{deposit?.currency}</p>
                  </td>
                  <td className="p-3">
                    <p>
                      {deposit?.createdAt
                        ? new Date(deposit?.createdAt).toLocaleDateString()
                        : ""}
                    </p>
                    <p>{new Date(deposit?.createdAt).toLocaleTimeString()}</p>
                  </td>
                  <td className="p-3">
                    <p
                      className={` text-center px-2 py-1 rounded-md text-base  ${
                        deposit?.status === "pending"
                          ? "bg-red-500"
                          : "bg-green-500"
                      }`}
                    >
                      {deposit?.status}
                    </p>
                  </td>
                  {/* <td className="p-3">
                    <MdDeleteOutline
                      className="w-7 h-7 text-red-500 cursor-pointer"
                      onClick={() => handleDeleteDeposit(deposit)}
                    />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Deposit;
