

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <div className="flex justify-center space-x-4 mb-4">
        <a href="#about" className="hover:text-gray-400">
          About
        </a>
        <a href="#services" className="hover:text-gray-400">
          Services
        </a>
        <a href="#contact" className="hover:text-gray-400">
          Contact
        </a>
        <a href="#privacy" className="hover:text-gray-400">
          Privacy Policy
        </a>
      </div>
      <p className="text-sm">
        &copy; {new Date().getFullYear()} Your Company. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
