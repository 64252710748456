import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Keys = () => {
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [paymentApiLink, setPaymentApiLink] = useState("");
  const [loading, setLoading] = useState(false);

  const accesstoken = getAccesstoken();

  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setPublicKey(userData?.user?.public_key || "");
      setSecretKey(userData?.user?.secret_key || "");
      setPaymentApiLink(userData?.user?.payment_api_link || "");
    };

    fetchUser();
  }, [accesstoken]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      public_key: publicKey,
      secret_key: secretKey,
      payment_api_link: paymentApiLink,
    };

    try {
      await axios.put(
        `${import.meta.env.VITE_API_URL}/auth/update-user`,
        body,
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      );

      setLoading(false);
      toast.success("Uploaded Successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[85vh] custom_container py-10 bg-gray-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-8 md:w-2/3 lg:w-1/2 w-full">
        <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-800">
          Upload Your Keys
        </h1>
        <p className="w-full text-center mx-auto mt-3 text-base text-gray-600">
          You can upload your keys here. If you don't have any keys, please
          collect them from the main website. Because without keys, you can't
          withdraw or deposit money.
        </p>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-10">
          <div className="flex flex-col gap-2">
            <label className="text-gray-700 font-semibold" htmlFor="publicKey">
              Public Key
            </label>
            <input
              type="text"
              id="publicKey"
              placeholder="Public Key"
              value={publicKey}
              onChange={(e) => setPublicKey(e.target.value)}
              className="py-3 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-gray-700 font-semibold" htmlFor="secretKey">
              Secret Key
            </label>
            <input
              type="text"
              id="secretKey"
              placeholder="Secret Key"
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
              className="py-3 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-gray-700 font-semibold"
              htmlFor="paymentApiLink"
            >
              Payment API Link
            </label>
            <input
              type="text"
              id="paymentApiLink"
              placeholder="Enter Payment API Link"
              value={paymentApiLink}
              onChange={(e) => setPaymentApiLink(e.target.value)}
              className="py-3 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <button
            type="submit"
            className="px-4 py-3 bg-blue-500 rounded-md text-white font-semibold hover:bg-blue-600 transition duration-300"
          >
            {loading ? "Updating..." : "Update"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Keys;
