import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Home = () => {
  const [user, setUser] = useState<UserType | null>(null);
  const accesstoken = getAccesstoken();

  useEffect(() => {
    if (!accesstoken) {
      return () => {};
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  return (
    <div className="min-h-[80vh] flex flex-col items-center justify-center px-5 md:px-10">
      <h1 className="text-2xl md:text-5xl font-bold text-center">
        Withdraw or deposit money in your account.
      </h1>

      <div className="mt-10 flex flex-col md:flex-row items-center gap-3">
        {user?.id ? (
          <>
            <Link
              to="/keys"
              className="px-5 py-2 bg-orange-500 border-none rounded-md text-white"
            >
            Upload Your Keys
            </Link>
            <Link
              to="/withdraws"
              className="px-5 py-2 bg-orange-500 border-none rounded-md text-white"
            >
              Dashboard
            </Link>
          </>
        ) : (
          <>
            <p className="text-xl font-mono text-center">
              Login to your account to get started.
            </p>
            <Link
              to="/sign-in"
              className="px-5 py-2 bg-orange-500 border-none rounded-md text-white"
            >
              Sign In
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
