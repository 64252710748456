import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { UserType } from "../../Types/UserType";
import { WithdrawType } from "../../Types/WithdrawType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Withdraw = () => {
  const navigate = useNavigate();
  const url = import.meta.env.VITE_API_URL;
  const [withdraws, setWithdraws] = useState<WithdrawType[]>([]);
  const [user, setUser] = useState<UserType | null>(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawNumber, setWithdrawNumber] = useState("");
  const [withdrawMethod, setWithdrawMethod] = useState("");
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedWithdraw, setSelectedWithdraw] = useState("");

  const openModal = (withdraw: WithdrawType) => {
    setSelectedWithdraw(withdraw?.payment_id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedWithdraw("");
    setIsOpen(false);
  };

  // get user
  const accesstoken = getAccesstoken();
  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  const handlePaymentCheck = useCallback(
    async ({ payment_id }: { payment_id: string }) => {
      try {
        await axios.post(`${url}/transaction/withdraw/success/manual`, {
          payment_id: payment_id,
          public_key: user?.public_key,
          payment_api_link: user?.payment_api_link,
        });

        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    [url, user]
  );

  const handleSuccessPaymentCheck = useCallback(
    async ({ payment_id }: { payment_id: string }) => {
      try {
        await axios.post(
          `${url}/transaction/withdraw/success/manual/check-success`,
          {
            payment_id: payment_id,
            public_key: user?.public_key,
            payment_api_link: user?.payment_api_link,
          }
        );

        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    [url, user]
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchWithdraws = async () => {
      const getWithdraws = await axios.get(
        `${url}/transaction/withdraw/${user.id}`
      );

      // console.log(getWithdraws);
      setWithdraws(getWithdraws?.data?.withdraws);

      // // Call handlePaymentCheck for each withdraw entry
      // getWithdraws?.data?.withdraws
      //   ?.filter((withdraw: WithdrawType) => withdraw?.status !== "Success")
      //   .forEach((withdraw: WithdrawType) => {
      //     handlePaymentCheck({ payment_id: withdraw?.payment_id });
      //   });

      // // Call handleSuccessPaymentCheck for each withdraw entry
      // getWithdraws?.data?.withdraws
      //   ?.filter((withdraw: WithdrawType) => withdraw?.status === "Success")
      //   .forEach((withdraw: WithdrawType) => {
      //     handleSuccessPaymentCheck({ payment_id: withdraw?.payment_id });
      //   });
    };

    fetchWithdraws();
  }, [user, url, handlePaymentCheck, handleSuccessPaymentCheck]);

  const handleConfirmReject = async () => {
    if (selectedWithdraw) {
      try {
        await axios.put(`${url}/transaction/withdraw/reject`, {
          payment_id: selectedWithdraw,
          secret_key: user?.secret_key,
          payment_api_link: user?.payment_api_link,
        });

        toast.success("Withdrawal rejected.");

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        // console.log(error);
        toast.error("Failed to reject withdrawal.");
      }
      closeModal();
    }
  };

  const handleWithdraw = async (e: React.FormEvent) => {
    e.preventDefault();
    setWithdrawLoading(true);

    if (!user?.public_key || !user?.secret_key || !user?.payment_api_link) {
      toast.error("Please update your payment information first.");
      setWithdrawLoading(false);
      navigate("/keys");
    } else if (!withdrawMethod) {
      setWithdrawLoading(false);
      return toast.error("Please select a valid withdrawal method.");
    }

    if (!withdrawNumber) {
      setWithdrawLoading(false);
      return toast.error("Please enter a withdrawal number.");
    }

    if (withdrawNumber.length < 11 && !/^\d+$/.test(withdrawNumber)) {
      setWithdrawLoading(false);
      return toast.error("Please enter a valid withdrawal number.");
    }

    if (!withdrawAmount) {
      setWithdrawLoading(false);
      return toast.error("Please enter a valid withdrawal amount.");
    }

    try {
      // console.log(withdrawAmount, withdrawNumber, withdrawMethod);

      await axios.post(`${url}/transaction/withdraw`, {
        amount: Number(withdrawAmount),
        userId: user?.id,
        public_key: user?.public_key,
        secret_key: user?.secret_key,
        payment_api_link: user?.payment_api_link,
        payment_method: withdrawMethod,
        withdraw_number: withdrawNumber,
      });

      // console.log(response?.data);
      setWithdrawLoading(false);
      setWithdrawAmount("");
      setWithdrawNumber("");
      setWithdrawMethod("");

      toast.success("Withdrawal is on process. Please wait for confirmation.");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setWithdrawLoading(false);
      // console.log(error);
      toast.error("Failed to withdraw. Check your number , amount and method.");
    }
  };

  return (
    <section className=" min-h-[85vh] custom_container py-10">
      <h1 className="text-3xl font-bold text-left mb-5">Withdraws</h1>

      <div className="flex gap-2 items-center">
        <h1 className="text-2xl font-semibold">Balance:</h1>
        <p className="text-xl font-bold text-red-500">${user?.balance}</p>
      </div>

      <div className="w-full lg:w-2/4 mt-10">
        <form onSubmit={handleWithdraw} className="flex flex-col gap-2">
          <select
            name="payment_method"
            id="payment_method"
            className="py-2 px-3 border border-gray-700 rounded-md"
            onChange={(e) => setWithdrawMethod(e.target.value)}
          >
            <option value="">Payment Method</option>
            <option value="bkash">Bkash</option>
            <option value="nagad">Nagad</option>
          </select>
          <input
            type="text"
            placeholder="Withdraw Number"
            value={withdrawNumber}
            onChange={(e) => setWithdrawNumber(e.target.value)}
            className="py-2 px-3 border border-gray-700 rounded-md"
          />
          <input
            type="text"
            placeholder="Withdraw Amount"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            className="py-2 px-3 border border-gray-700 rounded-md"
          />

          <button
            type="submit"
            className="px-3 py-2 bg-orange-500 border-none rounded-md text-white flex items-center justify-center"
            disabled={withdrawLoading}
          >
            {withdrawLoading ? <Loading /> : "Withdraw"}
          </button>
        </form>
      </div>

      <div className="mx-auto text-gray-100 mt-10 w-full">
        <div className="overflow-x-auto rounded-md w-full">
          <table className="w-full text-xs rounded-md">
            <colgroup>
              <col style={{ width: "150px" }} />
              <col style={{ width: "150px" }} />
              <col style={{ width: "150px" }} />
              <col style={{ width: "150px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "200px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "250px" }} />
              <col style={{ width: "100px" }} />
            </colgroup>
            <thead className="bg-gray-600">
              <tr className="text-left text-base whitespace-nowrap">
                <th className="p-3">Date & Time</th>
                <th className="p-3">Payment Id</th>
                <th className="p-3">Payment Method</th>
                <th className="p-3">Payout Number</th>
                <th className="p-3">Amount</th>
                <th className="p-3">TRX Id</th>
                <th className="p-3">Currency</th>
                <th className="p-3">Status</th>
                <th className="p-3">Reason</th>
                <th className="p-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {withdraws?.map((withdraw: WithdrawType) => (
                <tr
                  key={withdraw?.id}
                  className="border-b border-opacity-20 border-gray-700 bg-gray-900 text-sm"
                >
                  <td className="p-3 whitespace-nowrap">
                    <p>
                      {withdraw?.createdAt
                        ? new Date(withdraw?.createdAt).toLocaleDateString()
                        : ""}
                    </p>
                    <p>{new Date(withdraw?.createdAt).toLocaleTimeString()}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.payment_id}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.payment_method}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.withdraw_number}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.amount}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.trxId && withdraw?.trxId}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.currency}</p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p
                      className={`text-center px-2 py-1 rounded-md text-base ${
                        withdraw?.status === "Pending"
                          ? "bg-yellow-500"
                          : withdraw?.status === "Success"
                          ? "bg-green-500"
                          : withdraw?.status === "Rejected" ||
                            withdraw?.status === "Awaited"
                          ? "bg-red-500"
                          : "bg-blue-500"
                      }`}
                    >
                      {withdraw?.status}
                    </p>
                  </td>
                  <td className="p-3 whitespace-nowrap">
                    <p>{withdraw?.reason && withdraw?.reason}</p>
                  </td>
                  <td className={`p-3 cursor-pointer`}>
                    <button
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => openModal(withdraw)}
                    >
                      <img src="./public/cross.png" alt="" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-20"></div>
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-gray-800 px-4 py-3 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-white">
                Are you sure you want to reject this withdrawal?
              </h3>
            </div>
            <div className="bg-gray-900 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleConfirmReject}
              >
                Yes, Reject
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Withdraw;
