import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Dashboard = () => {
  const url = import.meta.env.VITE_API_URL;
  const navigate = useNavigate();

  const [depositLoading, setDepositLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [user, setUser] = useState<UserType | null>(null);
  // state
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [withdrawNumber, setWithdrawNumber] = useState("");
  const [withdrawMethod, setWithdrawMethod] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  // tab state

  // get user
  const accesstoken = getAccesstoken();
  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  const handleWithdraw = async (e: React.FormEvent) => {
    e.preventDefault();
    setWithdrawLoading(true);

    try {
      if (!user?.public_key || !user?.secret_key || !user?.payment_api_link) {
        toast.error("Please update your payment information first.");
        setWithdrawLoading(false);
        navigate("/keys");
      } else if (!withdrawAmount || !withdrawNumber || !withdrawMethod) {
        setWithdrawLoading(false);
        return toast.error("Please enter a valid withdrawal amount.");
      }

      // console.log(withdrawAmount, withdrawNumber, withdrawMethod);

      const response = await axios.post(`${url}/transaction/withdraw`, {
        amount: Number(withdrawAmount),
        userId: user?.id,
        public_key: user?.public_key,
        secret_key: user?.secret_key,
        payment_api_link: user?.payment_api_link,
        payment_method: withdrawMethod,
        withdraw_number: withdrawNumber,
      });

      console.log(response?.data);
      setWithdrawLoading(false);
      setWithdrawAmount("");
      setWithdrawNumber("");
      setWithdrawMethod("");

      toast.success("Withdrawal is on process. Please wait for confirmation.");
      navigate("/withdraws");
    } catch (error) {
      setWithdrawLoading(false);
      console.log(error);
      toast.error("Invalid withdrawal amount or insufficient balance.");
    }
  };

  const handleDeposit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setDepositLoading(true);

      if (!user?.secret_key || !user?.payment_api_link) {
        toast.error("Please update your payment information first.");
        navigate("/keys");
      }

      if (!depositAmount) {
        return alert("Please enter a valid withdrawal amount.");
      }

      const response = await axios.post(`${url}/transaction/deposit`, {
        amount: Number(depositAmount),
        userId: user?.id,
        payment_api_link: user?.payment_api_link,
        secret_key: user?.secret_key,
      });

      if (response?.data) {
        toast.success("Deposit successful");
        setDepositAmount("");

        console.log(response?.data);

        const paymentUrl = response?.data?.paymentResponse?.data?.payment_url;
        // console.log(paymentUrl);
        if (paymentUrl) {
          window.location.href = paymentUrl;
        }

        setDepositLoading(false);
      }

      // console.log(response?.data?.paymentResponse);
    } catch (error) {
      console.log(error);
      toast.error("Invalid withdrawal amount or insufficient balance.");
      setDepositLoading(false);
    }
  };

  return (
    <section className="min-h-[85vh] custom_container py-10 ">
      <div className="w-full flex items-center justify-center flex-col">
        <div className="flex gap-2 items-center">
          <h1 className="text-3xl font-semibold">Balance:</h1>
          <p className="text-2xl font-bold text-red-500">${user?.balance}</p>
        </div>

        <div className="w-full lg:w-2/4 mt-10">
          <form onSubmit={handleWithdraw} className="flex flex-col gap-2">
            <select
              name="payment_method"
              id="payment_method"
              className="py-2 px-3 border border-gray-700 rounded-md"
              onChange={(e) => setWithdrawMethod(e.target.value)}
            >
              <option value="">Payment Method</option>
              <option value="bkash">Bkash</option>
              <option value="nagad">Nagad</option>
            </select>
            <input
              type="text"
              placeholder="Withdraw Number"
              value={withdrawNumber}
              onChange={(e) => setWithdrawNumber(e.target.value)}
              className="py-2 px-3 border border-gray-700 rounded-md"
            />
            <input
              type="text"
              placeholder="Withdraw Amount"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              className="py-2 px-3 border border-gray-700 rounded-md"
            />

            <button
              type="submit"
              className="px-3 py-2 bg-orange-500 border-none rounded-md text-white flex items-center justify-center"
              disabled={withdrawLoading}
            >
              {withdrawLoading ? <Loading /> : "Withdraw"}
            </button>
          </form>
          <div className="flex flex-col gap-2 mt-10">
            <form onSubmit={handleDeposit} className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Deposit Amount"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                className="py-2 px-3 border border-gray-700 rounded-md"
              />
              <button
                type="submit"
                className="px-3 py-2 bg-blue-500 border-none rounded-md text-white flex items-center justify-center"
                disabled={depositLoading}
              >
                {depositLoading ? <Loading /> : "Deposit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
