import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Header = () => {
  const [user, setUser] = useState<UserType | null>(null);
  const accesstoken = getAccesstoken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  const logout = () => {
    localStorage.removeItem("access_token");
    setUser(null);
    navigate("/sign-in");
  };

  return (
    <header className=" bg-gray-100 text-black shadow-md">
      <div className="custom_container flex flex-col md:flex-row justify-between items-center p-4">
        <div className="text-2xl font-bold text-center">
          <Link to="/" className="flex items-end">
            <img
              src="https://admin.karentpay.com/assets/karentpay%20Logo-DLmNB8vm.png"
              alt=""
              className="h-10"
            />
            <span className="text-sm font-mono">testing app</span>
          </Link>
        </div>
        <nav className="mt-5 md:mt-0">
          <ul className="flex space-x-2">
            {user?.id ? (
              <>
                <li className="bg-gray-600 border-gray-900 px-2 py-1 rounded-md cursor-pointer text-white hover:scale-105 transtion-transform duration-300 ">
                  <Link to="/keys" className="">
                    Keys
                  </Link>
                </li>
                <li className="bg-gray-600 border-gray-900 px-2 py-1 rounded-md cursor-pointer text-white hover:scale-105 transtion-transform duration-300 ">
                  <Link to="/withdraws" className="">
                    Withdraw
                  </Link>
                </li>
                <li className="bg-gray-600 border-gray-900 px-2 py-1 rounded-md cursor-pointer text-white hover:scale-105 transtion-transform duration-300 ">
                  <Link to="/deposits" className="">
                    Deposit
                  </Link>
                </li>
                <li
                  className="bg-gray-600 border-gray-900 px-2 py-1 rounded-md cursor-pointer text-white hover:scale-105 transtion-transform duration-300 "
                  onClick={() => logout()}
                >
                  Sign Out
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/sign-in"
                    className="bg-gray-600 border-gray-900 px-2 py-1 rounded-md cursor-pointer text-white hover:scale-105 transtion-transform duration-300 "
                  >
                    Sign In
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
