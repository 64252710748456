import axios from "axios";

export const getUser = async (token: string) => {
  const url = import.meta.env.VITE_API_URL;
  if (!token) {
    return null;
  }

  const response = await axios.get(`${url}/auth/logged-user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
