import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Deposit from "../pages/Deposit/Deposit";
import Home from "../pages/Home/Home";
import Keys from "../pages/Keys/Keys";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignIn/SignUp";
import Withdraw from "../pages/Withdraw/Withdraw";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        path: "/sign-up",
        element: <SignUp />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/keys",
        element: <Keys />,
      },
      {
        path: "/withdraws",
        element: <Withdraw />,
      },
      {
        path: "/deposits",
        element: <Deposit />,
      },
    ],
  },
]);
